<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-autocomplete
          dense
          outlined
          hide-details
          v-model="id_customer"
          :items="customerOptions"
          label="Khách hàng"
          placeholder="Khách hàng"
          class="c-input-small"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-file-input
          accept=".xlsx"
          v-model="file"
          label="Chọn file"
          placeholder="Chọn file"
          dense
          outlined
          hide-details
          class="c-input-small"
          append-icon="mdi-paperclip"
          prepend-icon=""
          @change="onInputFileChange"
        ></v-file-input>
      </v-col>

      <v-col cols="12" md="3">
        <v-btn
          color="success"
          block
          :disabled="isDisabledBtnConfirm"
          @click="confirmUid"
        >
          RUN
        </v-btn>
      </v-col>
    </v-row>

    <div class="mt-10">
      <v-simple-table
        fixed-header
        height="calc(100vh - 225px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">Tên</th>
              <th class="text-center">Số lượng thùng</th>
              <th class="text-center">Số lượng UID</th>
              <th class="text-center">UID/Thùng</th>
              <th class="text-center">SKU</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(name, key) in groupKeys"
              :key="`au_${key}`"
              class="text-center"
            >
              <td>{{ name }}</td>
              <td>{{ Object.keys(group_by_name[name]).length - 3 }}</td>
              <td>{{ group_by_name[name].codes.length }}</td>
              <td>
                {{
                  group_by_name[name].codes.length /
                  (Object.keys(group_by_name[name]).length - 3)
                }}
              </td>
              <td>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="group_by_name[name].sku"
                  label=""
                  placeholder=""
                  class="c-input-small"
                  :disabled="!!group_by_name[name].id_goods"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import { httpClient } from "@/libs/http";

export default {
  name: "ProcessFile",
  data: () => ({
    isLoading: false,
    file: null,
    customers: [],
    id_customer: null,
    group_by_name: {},
  }),
  computed: {
    customerOptions() {
      return [...this.customers].map((item) => ({
        text: item.company_name,
        value: item.id,
      }));
    },
    groupKeys() {
      return Object.keys(this.group_by_name);
    },
    isDisabledBtnConfirm() {
      if (!this.id_customer || this.groupKeys.length == 0) {
        return true;
      }

      let hasSku = true;
      for (let i = 0; i < this.groupKeys.length; i++) {
        const name = this.groupKeys[i];
        if (!this.group_by_name[name].sku) {
          hasSku = false;
        }
      }

      return !hasSku;
    },
  },
  mounted() {
    const { warehouses } = window.me;
    this.warehouses = [...warehouses];
    this.getCustomers();
  },
  methods: {
    async getCustomers() {
      const { data } = await httpClient.post(
        "/customer/v1/get-all-has-contract",
        { is_active: true }
      );
      this.customers = [...data];
    },
    resetAll() {
      this.group_by_name = {};
      this.file = null;
    },
    async confirmUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;
      try {
        await httpClient.post("/dc/v1/process-file-and-active", {
          group_by_name: this.group_by_name,
          id_customer: this.id_customer,
        });
        this.isLoading = false;
        this.resetAll();
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async onInputFileChange(file) {
      if (file) {
        this.$vToastify.info("Chờ chút nhé");

        const mappingFields = {
          "Code number": "name",
          "Package QR code": "code",
          "QR code": "code_type",
          "Carton QR code": "box_code",
          "Packages/carton": "package_carton",
          /* "Load quantities": "load_quantity",
          Quantities: "quantity",
          "Bags/carton": "bag_carton", */
        };

        const reader = new FileReader();
        const allItems = [];
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          for (let i = 0; i < workbook.SheetNames.length; i++) {
            const sheetName = workbook.SheetNames[i];
            const worksheet = workbook.Sheets[sheetName];
            const values = utils.sheet_to_json(worksheet);
            const items = [...values].map((v) => {
              const item = {};
              Object.keys(v).forEach((i) => {
                item[mappingFields[i]] = `${v[i]}`.trim();
              });
              return item;
            });
            allItems.push(...items);
          }

          this.validateData(allItems);
        };
        await reader.readAsArrayBuffer(file);
      }
    },
    async validateData(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error("Không có dữ liệu");
        return false;
      }

      const groupByName = {};
      const groupByUidAndBox = {};
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.code_type != "Package QR CODE") {
          continue;
        }

        if (!item.box_code || !item.code) {
          console.log(JSON.stringify(item));
          continue;
        }

        let code = item.code.toLowerCase();
        let boxCode = item.box_code.toLowerCase();

        const uidBox = `${code}_${boxCode}`;

        if (groupByUidAndBox[uidBox]) {
          continue;
        }

        const nameType = `${item.name} ${item.package_carton}`;
        if (!groupByName[nameType]) {
          groupByName[nameType] = {
            codes: [],
          };
        }

        if (!groupByName[nameType][boxCode]) {
          groupByName[nameType][boxCode] = [];
        }

        const codes = groupByName[nameType].codes;
        const duplicateCode = [...codes].find((c) => c == code);
        if (duplicateCode) {
          code = `${code}@${this.generateRandomString(5)}`.toLowerCase();
        }

        groupByName[nameType][boxCode].push(code);
        groupByName[nameType].codes.push(code);

        groupByUidAndBox[uidBox] = true;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;
      try {
        const { data } = await httpClient.post("/dc/v1/process-file", {
          group_by_name: groupByName,
          id_customer: this.id_customer,
        });
        console.log(data);
        this.isLoading = false;
        if (data.multi_ids && data.multi_ids.length > 0) {
          this.$vToastify.error("Lỗi: " + data.multi_ids.join("; "));
          return false;
        }
        this.group_by_name = { ...data.group_by_name };
        // this.writeFile(data);
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>
